// Login.js
export const LOGIN_EMAIL_ERROR = 'We can\'t find a match! Try again or contact us'
export const PASSWORD_CHANGED = 'Password successfully changed.'
export const WELCOME_MESSAGE = payload => `Welcome to ${payload}`
export const REQUIRED_FIELD = 'This field is required'
export const ENTER_VALID_EMAIL = 'Please enter a valid email address'
export const TROUBLE_LOGGING_IN_WITH_TEL = payload =>
	`If you're having trouble logging in, please <a href='mailto:${payload.supportEmail}'>email us</a> or call <a href='tel:${payload.supportNumber}\`'>${payload.supportNumber}</a>`
export const TROUBLE_LOGGING_IN_WITHOUT_TEL = payload =>
	`If you're having trouble logging in, please <a href='mailto:${payload.supportEmail}'>email us</a>`
export const GET_BENEFITS_AND_SELECTIONS_ERROR = 'Unfortunately we are experiencing a problem with your account, please contact the support team for help.'

// AddLifeEvent.js, contact-us.js, ActivationTerms.js, forgotten-password.js
export const PLEASE_COMPLETE_FORM = payload => `Please complete the form below to gain access to your ${payload} account`
export const STANDARD_LOGIN_ERROR = 'Unable to login'
export const ACCOUNT_LOCKED = 'You have unsuccessfully tried to login too many times - your account has been locked for 15 mins. Please try again later'
export const NO_ACCESS_LOGOUT = payload => `Sorry to see you go. Your company has advised us that you have left their employment and you should no longer have access to your ${payload.benefitBrandName} benefits package. If you do not believe that this is correct please <a href='mailto:${payload.supportEmail}'>contact us</a>.<br>
	Regards<br>
The ${payload.benefitBrandName} team`
// ResetPassword.js
// REQUIRED_FIELD used
export const CORRECT_HIGHLIGHTED_ERRORS =
	'Please correct the errors highlighted below'
export const CREATE_NEW_PASSWORD =
	'Almost there! Time to put your thinking cap on and create a new password.'

// forgotten-password.js
// REQUIRED_FIELD, ENTER_VALID_EMAIL used
export const FILL_OUT_FORMS = payload => `Please fill out the forms below to gain access to your ${payload} account.`
export const REGISTERED_EMAIL_FOR_PASSWORD_RESET =
	'We can reset your password. All we need is your registered email address.'
export const CONFIRM_NOT_ROBOT = 'Please confirm that you are not a robot!'
export const ON_WAY = "It's on the way..."
export const CHECK_INBOX =
	"Check your inbox! Don't forget to check your Junk & Spam folders."
export const NO_LINK_CONTACT = 'No link? Let us know!'

// contact-us.js
// REQUIRED_FIELD, FILL_OUT_FORMS used
export const LOADING_PROFILE = 'Loading profile'
export const LOADING_BENEFITS = 'Loading benefits'
export const MESSAGE_SENT = 'Your message has been sent!'
export const CONTACT_SUCCESS_TEST = payload =>
	'We will contact you as soon as possible.'
// `We will contact you within 24 hours. If your query is urgent, please call us on: ${payload.supportNumber}`

export const PROBLEM_LOADING = 'There was a problem loading this page'
export const TRY_AGAIN_IF_URGENT = payload =>
	`Please try again. If your query is urgent, please call us on: ${payload.supportNumber}`
export const PROVIDE_MESSAGE = 'Please provide a subject for your message'
export const SUBJECT_OF_ENQUIRY = 'What is the subject of your enquiry?'

// ActivationDateofBirth.js
export const DATE_NO_MATCH = 'This date does not match our records'
export const ERROR_REQUESTING_ACTIVATION = 'There was an error requesting your activation'
export const DOB_REQUIRED = 'Date of birth is required'
export const COMPLETE_FORM = 'Please complete the form'
export const ENTER_DOB_TO_START = payload => `Pop in your date of birth to get started! ${payload} is just a
few clicks away...`

// ActivationPassword.js
// CORRECT_HIGHLIGHTED_ERRORS, REQUIRED_FIELD, COMPLETE_FORM used
export const FAILED_TO_FETCH = 'Failed to fetch'
export const THINKING_CAPS_ON =
	'Almost there! Time to put your thinking cap on and create a new password.'

// ActivationTerms.js
// COMPLETE_FORM, FILL_OUT_FORMS used
export const CONFIRM_READ_TERMS =
	'Please confirm that you have read the terms and conditions'
export const NEARLY_THERE = 'Nearly there'
export const ERROR_REQUESTING_TERMS =
	'There was an error requesting the terms and conditions, please try again later'

// withValidActivationUrl.js
// LOADING_PROFILE used
export const ISSUE_REQUESTING_ACTIVATION_LINK =
	'Oops... there was an issue requesting your activation link'

// Verify.js
// DOB_REQUIRED used
export const BACK_ON_TRACK = "Let's get you back on track."
export const SECURITY_ENTER_DOB =
	'For security reasons, please enter your Date of Birth.'
export const DOB_NOT_VALID = 'This date of birth is not valid'

// withValidResetUrl.js
export const ISSUE_REQUESTING_RESET_LINK =
	'Oops... there was an issue requesting your password reset link'

// Password.js
export const DATA_SECURITY_CRITERIA =
	'Your data security is really important to us.Please meet all the criteria below!'

// TimeoutPopup.js
export const LOG_OUT_ALERT =
	"We will log you out soon to protect your account. If you want to stay logged in, that's fine."

// AddFamily.js
// DOB_REQUIRED used
export const FULLTIME_EDUCATION_REQUIRED = 'Full time education is required'
export const CORRECT_ERRORS = 'Please correct the errors below'
export const RELATIONSHIP_REQUIRED = 'Relationship is required'
export const SEX_REQUIRED = 'Sex is required'
export const TITLE_REQUIRED = 'Title is required'
export const FIRST_NAME_REQUIRED = 'First name is required'
export const SURNAME_REQUIRED = 'Surname is required'
export const SEX_TOOLTIP = 'This is your family member\'s sex as assigned at birth.'

// LifeEvent.js
export const LIFE_EVENT_MAIN = 'If you have experienced a major change in your life (e.g. birth, marriage, change in partner circumstances) please add a life event.'
export const ADD_LIFE_EVENT_ERROR = 'There was an error encountered when trying to add the life event'

// AddLifeEvent
// FILL_OUT_FORMS, REQUIRED_FIELD used

// ChangePassword.js
// CORRECT_HIGHLIGHTED_ERRORS, REQUIRED_FIELD, PASSWORD_CHANGED used
export const SUCCESS = 'Success'

// OptOut.js
export const WISH_TO_PROCEED = 'Are you sure you wish to proceed?'

// PayrollReport.js
// ProviderReport.js
export const NO_REPORTS_AVAILABLE =
	'It looks like you have no reports available'
export const ERROR_REQUESTING_PAYROLL_REPORTS =
	'There was an error requesting your payroll report'
export const ERROR_REQUESTING_PROVIDER_REPORTS =
	'There was an error requesting your provider report'

// Statement.js
export const PROBATION_ACCESS =
	"As you're on probation, you won't be able to access all benefits. We'll email you when you can!"
export const NO_STATEMENTS_AVAILABLE =
	'It looks like you have no statements available'
export const ERROR_REQUESTING_STATEMENTS =
	'There was an error requesting your statement, please try again later'

// UploadData.js
export const ERROR_REQUESTING_UPLOAD_TEMPLATE =
	'There was an error requesting the upload template'

export const SERVER_ERROR_UPLOAD_SUBMIT =
	'There was an error uploading your attachment'

export const SERVER_UPLOAD_DATA_SUCCESS =
	'Your file has been uploaded successfully'

export const SERVER_UPLOAD_ERROR_NOTIFICATION =
	'Please correct the errors below'

// NewTerms.js
// LOADING_PROFILE, REQUIRED_FIELD, CONFIRM_READ_TERMS used
export const SOMETHING_HAS_GONE_WRONG =
	'It looks like something has gone wrong, please try again later'

// WithValidProduct.js

export const BENEFIT_LOADING_ERROR = payload => `There is an issue loading this benefit. Please <a href='mailto:${payload}'>contact us</a>`
export const BENEFIT_DOESNT_EXIST_ERROR = 'Oops! The benefit you are looking for has been removed or relocated.'

// Product.js
export const NEW_APPLICATIONS_WARNING = payload =>
	`Any new applications or changes that you've made to your ${payload} will be active from `

export const NO_CONTRIBUTIONS_TAKEN =
	'No contributions have been taken yet. Your selection will become effective on '

// Setup/index.js -
export const MAX_COVER_AMOUNT_REACHED = amount => `This policy has a free cover limit, your cover amount is capped at ${amount}`

export const MASQUERADE_BANNER_NOTICE = (name, schemeYear) => `You are currently viewing the site as ${name} for the scheme year ${schemeYear}`

// CookiesNotice.js
export const NECESSARY_COOKIES = 'Essential cookies  are required to make this site function properly. They are necessary for site management and security. They need to always be on.'
export const NON_NECESSARY_COOKIES = 'This site may use use video services from providers such as Vimeo or Youtube to show you embedded videos on this site. Vimeo and Google may receive information about the videos that you watch for analytics purposes.'

// BenefitTakeUpDetail.js
export const ERROR_REQUESTING_TAKEUP_DATA =
	'There was an error requesting the benefit takeup data'

export const NO_TAKEUP_DATA = 'No benefit takeup data found'

// ApprovalSelections
export const ERROR_REQUESTING_APPROVALS_DATA = 'There was an error requesting the approvals data'
export const NO_APPROVALS_DATA = 'No approval data found'

// TwoFA
export const TWO_FA_SWITCHER_LABEL = 'Two factor authentication'
export const TWO_FA_SWITCHER_TEXT = 'Two factor authentication provides additional security for your account as once it is enabled you will need to provide not only your password  but also an authentication code that only you have to login. This adds an extra layer of security to the authentication process making it harder for anyone to gain access to your account.'
export const TWO_FA_ENABLE_STEP_1 = 'To register your device for two-factor authentication, please scan the QR code below with Google Authenticator or a similar authentication app.'
export const TWO_FA_ENABLE_STEP_2 = (order) => `To complete device registration, please enter the ${order} authentication code displayed in your app.`
export const TWO_FA_DISABLE_TEXT = 'You are about to disable two factor authentication this will make your account less secure. Click close if you do not wish to disable two factor authentication.'
export const TWO_FA_DISABLE_TEXT_ER = 'You are about to disable two factor authentication. Click close if you do not wish to disable two factor authentication.'
export const TWO_FA_DISABLE_TEXT_2 = 'Please enter the reason that you are disabling this feature.'
export const TWO_FA_DISABLE_ERROR = 'Please enter the reason two factor authentication is being disabled.'
export const TWO_FA_DISABLE_SUCCESS = 'Two factor authentication has been disabled'
export const TWO_FA_ENFORCE_1 = 'Your organisation requires you to set up two factor authentication on your account. Please follow the steps below.'
export const TWO_FA_ENFORCE_2 = 'To register your device for two factor authentication please scan the QR code below with Google authenticator or a similar authentication app.'
export const TWO_FA_IS_ACTIVATED = 'Two factor authentication has been enabled. Your account is more secure. Next time you login you will need to enter an authentication code as well as your password.'

// logout.js
export const LOGOUT_SUCCESS = 'You have been logged out successfully'
export const GO_TO_LOGIN = 'Go to login'
export const LOGOUT_ERROR = 'There was an error during the logout process. Please try again.'
export const RETRY = 'Retry'

export const LOADING = 'Loading'
