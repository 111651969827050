import { format, fromUnixTime } from 'date-fns';

export const formatDateField = (dateValue) => {
  if (!dateValue) return null;

  if (typeof dateValue === 'string') {
    const date = new Date(dateValue);
    return format(date, 'Y-MM-dd');
  } else if (dateValue?.timestamp) {
    return format(fromUnixTime(dateValue.timestamp), 'Y-MM-dd');
  }
  return dateValue;
};

export const formatFamilyMemberResponse = (responseMember) => {
  return {
    familyMemberId: responseMember.family_id,
    firstName: responseMember.family_fname,
    surname: responseMember.family_sname,
    dateOfBirth: formatDateField(responseMember.family_dob),
    gender: responseMember.family_gender_id,
    relationship: responseMember.family_famtype_id === 1 ? 'partner' : 'dependant',
    title: responseMember.family_tname,
    active: responseMember.family_active,
    fullTimeEducationEndDate: formatDateField(responseMember.family_ft_edu_ends),
    name: `${responseMember.family_fname} ${responseMember.family_sname}`
  };
};

export const prepareFamilyMemberForForm = (familyMember) => {
  if (!familyMember) return {};

  if (familyMember.gender === 1) { familyMember.gender = 'female' }
  else if (familyMember.gender === 2) { familyMember.gender = 'male' }

  return {
    familyMemberId: familyMember.familyMemberId,
    first_name: familyMember.firstName,
    surname: familyMember.surname,
    date_of_birth: familyMember.dateOfBirth,
    sex: familyMember.gender,
    fmember_type: familyMember.relationship,
    education: familyMember.fullTimeEducationEndDate,
    registered_disabled: familyMember.registeredDisabled,
    education_check: familyMember.fullTimeEdu,
    title: familyMember.title
  };
};